import Rails from "rails-ujs";
import Turbolinks from "turbolinks";
import Foundation from "foundation-sites";
import $ from "jquery";
import * as ActiveStorage from "activestorage";
import "slick-carousel/slick/slick";
import "slick-carousel/slick/slick.css";

import "application.scss";

import "images/logo.svg";
import "images/hero-wave.svg";
import "images/hero-wave-blue.svg";
import "images/hero-overlay.svg";
import "images/hero-overlay-white.svg";
import "images/hero-overlay-color.svg";
import "images/hero-overlay-flip-white.svg";
import "images/break-wave.svg";
import "images/cta-gradient.svg";
import "images/quote.svg";
import "images/home.svg";
import "images/med.svg";
import "images/time.svg";
import "images/email.svg";
import "images/phone.svg";
import "images/arrow.svg";
import "images/favicon.png";
import "images/close.svg";
import "images/facebook-cover.png";
import "images/gas-safe.svg";

Rails.start();
Turbolinks.start();
ActiveStorage.start();
Foundation.addToJquery($);

$(document).on("turbolinks:load", function() {
  $(this).foundation();

  $("[data-slick='hero']").slick({
    dots: true,
    arrows: false,
    autoplay: true,
    asNavFor: "[data-slick='hero_image']"
  });

  $("[data-slick='hero_image']").slick({
    dots: false,
    arrows: false,
    autoplay: true,
    asNavFor: "[data-slick='hero']"
  });

  $("[data-slick='usps']").slick({
    dots: false,
    arrows: false,
    autoplay: true,
    asNavFor: "[data-slick='usps_image']"
  });

  $("[data-slick='usps_image']").slick({
    dots: true,
    arrows: false,
    autoplay: true,
    asNavFor: "[data-slick='usps']"
  });

  $("[data-slick='about_usps']").slick({
    dots: true,
    arrows: false,
    autoplay: true,
    asNavFor: "[data-slick='about_usps_image']"
  });

  $("[data-slick='about_usps_image']").slick({
    dots: false,
    arrows: false,
    autoplay: true,
    asNavFor: "[data-slick='about_usps']"
  });

  $("[data-slick='testimonials']").slick({
    centerMode: true,
    centerPadding: 0,
    dots: false,
    arrows: false,
    slidesToShow: 3,
    responsive: [{
      breakpoint: 1064,
      settings: {
        slidesToShow: 1,
        centerMode: false
      }
    }]
  });

  $("[data-slick='products']").slick({
    dots: true,
    arrows: false,
    slidesToShow: 2,
    responsive: [{
      breakpoint: 1064,
      settings: {
        slidesToShow: 1
      }
    }]
  });

  const countTo = () =>
    $("[data-count]").each(function() {
      $(this)
        .prop("Counter", 0)
        .animate({
          Counter: $(this).attr("data-count-value")
        }, {
          duration: 4000,
          easing: "swing",
          step: function(now) {
            $(this).text(Math.ceil(now));
          }
        });
    });

  $(window).on("scroll", function() {
    const $scroll = $(window).scrollTop();

    if ($(".about-stats").length > 0) {
      if ($scroll + 500 > $(".about-stats").position().top) {
        countTo();
      }
    }
  });
});